<template>
  <div>
    <b-card no-body class="p-2 mb-1">
      <b-row>
        <b-col
          v-for="(item, index) in botones"
          :key="index"
          class="mx-0 px-0"
          xl="3"
          lg="3"
          md="3"
          sm="6"
          xs="12"
        >
          <b-button
            size="md"
            :variant="
              btnActivo === item.id
                ? resolveServicioVariant(item.id)
                : 'flat-secondary'
            "
            block
            @click="filtrarServicios(item.id)"
          >
            <feather-icon :icon="item.icon" />
            {{ item.nombre }}
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <b-card
      no-body
      class="mb-0 px-2 pt-1"
      :class="`border-${resolveServicioVariant(btnActivo)}`"
    >
      <b-table
        id="my-table"
        ref="refTablaBecarios"
        class="position-relative"
        show-empty
        head-variant="light"
        hover
        small
        responsive
        :busy.sync="isBusy"
        :items="myProvider"
        :no-provider-filtering="true"
        :fields="tableColumns"
        :current-page="currentPage"
        :empty-text="'No hay registros para mostrar'"
        :empty-filtered-text="
          'No hay registros que coincidan con su solicitud.'
        "
        :per-page="perPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        caption-top
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle" />
            <strong>Cargando...</strong>
          </div>
        </template>

        <template #table-caption>
          <b-row class="px-1">
            <b-col cols="4">
              <span class="text-secondary"
                ><strong>LISTA DE BECARIOS</strong>
              </span>
              <span class="ml-2">
                <b-button
                  size="sm"
                  variant="outline-success"
                  @click="migrarActualizarBecarios"
                >
                  actualizar
                  <feather-icon icon="RefreshCcwIcon"/></b-button
              ></span>
            </b-col>
            <b-col cols="8" class="d-flex justify-content-end">
              <div class="mr-1">
                <label>Mostrar</label>
                <b-form-select
                  v-model="perPage"
                  :options="pageOptions"
                  :clearable="false"
                  size="md"
                  style="width:auto"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>registros</label>
              </div>
              <div>
                <b-form-input
                  v-model="filter"
                  type="search"
                  placeholder="Buscar..."
                  size="md"
                />
              </div>
            </b-col>
          </b-row>
        </template>

        <!-- Column: index -->
        <template #cell(index)="row">
          {{ from + row.index }}
        </template>

        <!-- Column: becario -->
        <template #cell(becario)="row">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :variant="`light-primary`"
                :text="avatarText(recorteName(row.item.nombres))"
              />
            </template>
            <b-link class="font-weight-bold d-block text-nowrap">
              {{ row.item.nombres }}
            </b-link>
            <small class="text-primary"
              ><strong>DNI:</strong>
              {{ row.item.persona.numero_documento }}</small
            >
            <br />
            <small class="text-primary"
              ><strong>CELULAR:</strong> {{ row.item.persona.celular }}</small
            >
          </b-media>
        </template>

        <!-- Column: etnia -->
        <template #cell(etnia)="row">
          {{
            row.item.persona.pueblo_indigena &&
              row.item.persona.pueblo_indigena.nombre
          }}
        </template>

        <!-- Column: escuela -->
        <template #cell(escuela)="row">
          {{ row.item.escuela.nombre }}
        </template>

        <!-- Servicio -->
        <template #cell(servicio)="row">
          <feather-icon
            :icon="resolveServicioIcon(row.item.servicio.codigo)"
            size="15"
            class="mr-50"
          />
          {{ row.item.servicio.nombre }}
        </template>

        <!-- Column: estado -->
        <template #cell(estado)="data">
          <b-badge
            pill
            :variant="`light-${resolveEstadoVariant(data.item.estado)}`"
            class="text-capitalize"
          >
            {{ data.item.estado }}
          </b-badge>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Mostrando registros del {{ from }} al {{ to }} de un total de
              {{ totalRows }} registros</span
            >
          </b-col>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BSpinner,
  BFormSelect,
  BMedia,
  BAvatar,
  BBadge,
  BPagination,
  BButton,
  BLink,
} from "bootstrap-vue";
// import vSelect from 'vue-select'
import { onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import store from "@/store";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import becariosStoreModule from "./becariosStoreModule";

export default {
  components: {
    BFormSelect,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BSpinner,
    BBadge,
    BPagination,
    BButton,
    BMedia,
    BAvatar,
    BLink,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isBusy: false,
      from: 1,
      to: 1,
      totalRows: 1,
      currentPage: 1,
      perPage: 500,
      pageOptions: [50, 100, 300, 500],
      sortBy: "id",
      sortDesc: false,
      // sortDirection: 'desc',
      filter: "",
      filterOn: [],
      idConvocatoria: 0,
      btnActivo: 0,
      textHistorial: "Historial",
      botones: [],
      filtroServicio: [],
    };
  },
  created() {
    store
      .dispatch("becarios-store-module/getServicios")
      .then((res) => {
        this.botones = res.data;
        this.botones.unshift({
          id: 0,
          nombre: "Todos",
          icon: "MenuIcon",
        });
        this.botones.forEach((value, index) => {
          if (value.id === 13) {
            this.botones[index].icon = "CoffeeIcon";
            return;
          }
          if (value.id === 14) {
            this.botones[index].icon = "HomeIcon";
            return;
          }
          if (value.id === 15) {
            this.botones[index].icon = "BriefcaseIcon";
            return;
          }
          this.botones[index].icon = "MenuIcon";
        });
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "¡Error en cargar los servicios!",
            icon: "CheckCircleIcon",
            variant: "danger",
          },
        });
      });
  },
  methods: {
    actualizarTabla() {
      this.filter = null;
      this.$refs.refTablaBecarios.refresh();
    },
    async myProvider(ctx) {
      // eslint-disable-next-line no-param-reassign
      ctx.servicios_id = this.filtroServicio;
      const promise = store.dispatch("becarios-store-module/getBecarios", {
        queryParams: ctx,
      });
      return promise.then((response) => {
        const { data, total, from, to } = response.data;

        const items = data;
        this.totalRows = total || 0;
        this.from = from || 0;
        this.to = to || 0;
        return items || [];
      });
    },
    recorteName(name) {
      // eslint-disable-next-line no-param-reassign
      if (name === null || name === "") {
        name = "B";
      }
      let recorte = [];
      recorte = name.split("");
      return `${recorte[0]} ${recorte[1]}`;
    },
    filtrarServicios(id) {
      if (id === 0) {
        this.filtroServicio = [];
      } else {
        this.filtroServicio = [id];
      }
      this.actualizarTabla();
      this.btnActivo = id;
    },
    migrarActualizarBecarios() {
      const data = { servicios_id: this.botones.map((val) => val.id) };
      store
        .dispatch("becarios-store-module/migrarActualizarBecarios", data)
        .then(() => {
          this.actualizarTabla();
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "¡Actualización exitosa!",
              icon: "CheckCircleIcon",
              variant: "success",
            },
          });
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "¡Error en la actualización!",
              icon: "CheckCircleIcon",
              variant: "danger",
            },
          });
        });
    },
  },
  setup() {
    const BECARIOS_APP_STORE_MODULE_NAME = "becarios-store-module";

    if (!store.hasModule(BECARIOS_APP_STORE_MODULE_NAME))
      store.registerModule(BECARIOS_APP_STORE_MODULE_NAME, becariosStoreModule);

    onUnmounted(() => {
      if (store.hasModule(BECARIOS_APP_STORE_MODULE_NAME))
        store.unregisterModule(BECARIOS_APP_STORE_MODULE_NAME);
    });

    // const roleOptions = [
    //   { label: 'Comedor', value: 'Comedor' },
    //   { label: 'Residencia', value: 'Residencia' },
    //   { label: 'Bolsa de trabajo', value: 'Bolsa de trabajo' },
    // ]

    const tableColumns = [
      { key: "index", label: "N°" },
      { key: "becario", label: "Becario" },
      { key: "etnia", label: "etnia", sortable: true },
      { key: "escuela", label: "escuela", sortable: true },
      { key: "servicio", label: "servicio", sortable: true },
      { key: "estado", label: "estado", sortable: true },
    ];

    const resolveEstadoVariant = (status) => {
      if (status === "pendiente") return "warning";
      if (status === "activo") return "success";
      if (status === "inactivo") return "danger";
      return "secondary";
    };
    const resolveServicioVariant = (value) => {
      if (value === 13) return "danger";
      if (value === 14) return "success";
      if (value === 15) return "info";
      return "primary";
    };
    const resolveServicioIcon = (value) => {
      if (value === "BECA-COMEDOR") return "CoffeeIcon";
      if (value === "BECA-RESIDENCIA") return "HomeIcon";
      if (value === "BECA-BOLSA") return "BriefcaseIcon";
      return "TargetIcon";
    };

    return {
      becariosStoreModule,
      tableColumns,
      avatarText,
      resolveServicioVariant,
      resolveServicioIcon,
      resolveEstadoVariant,
      // roleOptions,
    };
  },
};
</script>

<style lang="scss" scoped></style>

<style lang="scss"></style>
